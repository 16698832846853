import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useCommon } from '../../contexts/CommonContext';
import { localization } from './localization';
import { useContentLocalizer } from '../../hooks/useContentLocalizer';
import { useTenant } from '../../contexts/TenantContext';
import { Card, Container, Footer, SubTitle } from './styles';
import {
  BdsButton,
  BdsCheckbox,
  BdsGrid,
  BdsPagination,
  BdsSelect,
  BdsSkeleton,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react';
import { showToast } from '../../api/CommonApi';
import { ApplicationApi } from '../../api/ApplicationApi';
import { BlipSearch } from '../../components/BlipSearchDs';
import ListBotCalls from './ListBotCalls';
import ListEmpty from './ListEmpty';
import LoadingState from './LoadingState';
import { WhatsAppApplication } from './typing/models/whatsapp-application.interface';
import { WhatsAppPhoneNumberSettings } from './typing/interfaces/whatsapp-phone-number-settings.interface';
import { WhatsAppCallingStatus } from './typing/enums/whatsapp-calling-status.enum';
import { WhatsAppSetSettingsResponse } from './typing/models/whatsapp-set-settings-response.interface';
import { WhatsAppCallingIconVisibility } from './typing/enums/whatsapp-calling-icon-visibility.enum';

const Calls: React.FC = () => {
  const { setHeaderContent } = useCommon();
  const {
    tenant: { info },
  } = useTenant();

  const [botsEnabled, setBotsEnabled] = useState(0);
  const [botsDisabled, setBotsDisabled] = useState(0);
  const [configBots, setConfigBots] = useState<WhatsAppApplication[]>([]);
  const [botsFiltered, setBotsFiltered] = useState<WhatsAppApplication[]>([]);
  const [showButtonEnabledAll, setShowButtonEnabledAll] = useState(false);
  const [showButtonDisabledAll, setShowButtonDisabledAll] = useState(false);
  const [selectAllBots, setSelectAllBots] = useState(false);
  const [amountSelectedBots, setAmountSelectedBots] = useState(5);
  const [selectPerPage, setSelectPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [pages, setPages] = useState(1);
  const [search, setSearch] = useState<{ searchTerm: string; filterByEnabled?: boolean }>({
    searchTerm: '',
    filterByEnabled: undefined,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isEnablingCalling, setIsEnablingCalling] = useState(false);
  const [isDisablingCalling, setIsDisablingCalling] = useState(false);
  const translate = useContentLocalizer(localization);
  const optionsPerPage = [
    { label: 5, value: 5 },
    { label: 10, value: 10 },
    { label: 25, value: 25 },
    { label: 50, value: 50 },
  ];
  const optionsFilterBots = [
    { label: translate.filter.allBots, value: undefined },
    { label: translate.filter.onlyEnabled, value: true },
    { label: translate.filter.onlyDisabled, value: false },
  ];

  const getBots = async () => {
    try {
      const { response: owners }: { response: WhatsAppApplication[] } = await ApplicationApi.sendApplicationRequest(
        'getWhatsAppApplicationsByTenant',
        info.id,
      );
      const mappedOwners = mapConfiguration(owners);

      setTotalRows(mappedOwners.length);

      mappedOwners.sort((a, b) => {
        const hasPermissionCondition = a.hasPermission === b.hasPermission ? 0 : a.hasPermission ? -1 : 1;
        const isEnabledCondition = a.isCallingEnabled === b.isCallingEnabled ? 0 : a.isCallingEnabled ? -1 : 1;
        const ascNameCondition = a.name.localeCompare(b.name);

        return hasPermissionCondition || isEnabledCondition || ascNameCondition;
      });

      return mappedOwners;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const mapConfiguration = (owners: WhatsAppApplication[] = []): WhatsAppApplication[] => {
    return owners.map((application) => {
      let created: Date | undefined;
      let updated: Date | undefined;

      if (application.created) {
        created = new Date(application.created);
      }

      if (application.updated) {
        updated = new Date(application.updated);
      }

      return new WhatsAppApplication({ ...application, created, updated });
    });
  };

  const filterBots = async ({ searchTerm, filterByEnabled }: { searchTerm: string; filterByEnabled?: boolean }) => {
    const botNormalized = normalize(searchTerm);

    const filterBy = filterByEnabled !== undefined;

    const botsFiltered: WhatsAppApplication[] =
      searchTerm || filterBy
        ? configBots.filter((owner) => {
            const nameNormalized = normalize(owner.name);
            const phoneNormalized = normalize(owner.phoneNumber);
            const checkedStatus = filterByEnabled === owner.isCallingEnabled;
            const checkedBot = nameNormalized.includes(botNormalized) || phoneNormalized.includes(botNormalized);

            if (searchTerm && filterBy) {
              return checkedBot && checkedStatus;
            }

            if (searchTerm) {
              return checkedBot;
            }

            if (filterBy) {
              return checkedStatus;
            }
          })
        : configBots;

    setTotalRows(botsFiltered.length);

    const amountSelected = configBots.filter((bot) => bot.selected).length;
    const buttonDisabled = configBots.some((application) => application.selected && application.isCallingEnabled);
    const buttonEnabled = configBots.some((application) => application.selected && !application.isCallingEnabled);

    setAmountSelectedBots(amountSelected);
    setShowButtonDisabledAll(buttonDisabled);
    setShowButtonEnabledAll(buttonEnabled);
    setPages(Math.ceil(botsFiltered.length / selectPerPage));
    setBotsFiltered(botsFiltered.slice((page - 1) * selectPerPage, selectPerPage * page));
  };

  const normalize = (value) => {
    return value
      ?.toLowerCase()
      ?.trim()
      ?.normalize('NFD')
      ?.replace(/[\u0300-\u036f]/g, '');
  };

  const handleToggleFilter = () => {
    const filter = search.filterByEnabled !== true ? true : false;
    const searchObject = { searchTerm: search.searchTerm, filterByEnabled: filter };

    setSearch(searchObject);
    filterBots(searchObject);
  };

  const onFilter = useCallback(
    (searchTerm: string, status?: boolean) => {
      setSearch({ searchTerm: searchTerm, filterByEnabled: status });

      if (page > 1) {
        setPage(1);
        return;
      }

      filterBots({ searchTerm: searchTerm, filterByEnabled: status });
    },
    [filterBots],
  );

  const onEnableCalling = async (
    application: WhatsAppApplication,
    enable: boolean,
    shouldShowToast = true,
  ): Promise<boolean> => {
    try {
      const settings = {
        calling: { status: enable ? WhatsAppCallingStatus.ENABLED : WhatsAppCallingStatus.DISABLED },
      } as WhatsAppPhoneNumberSettings;

      if (
        (application.phoneNumberSettings?.calling?.status ?? WhatsAppCallingStatus.NOT_SET) ===
        WhatsAppCallingStatus.NOT_SET
      ) {
        settings.calling!.callIconVisibility = WhatsAppCallingIconVisibility.DISABLE_ALL;
      }

      const {
        response: {
          resource: { success, error },
        },
      }: { response: { resource: WhatsAppSetSettingsResponse } } = await ApplicationApi.sendApplicationRequest(
        'setApplicationPhoneNumberSettings',
        {
          tenantId: info.id,
          applicationIdentity: application.identity,
          settings,
        },
      );

      if (error) {
        throw error;
      }

      if (shouldShowToast) {
        showToast({
          type: 'success',
          message: translate.formatString(
            translate.enabledCalls.success,
            enable ? translate.enabledCalls.enabled : translate.enabledCalls.disabled,
          ),
        });
      }

      if (success) {
        application.isCallingEnabled = enable;

        if (settings.calling?.callIconVisibility) {
          application.isCallingIconVisible =
            settings.calling.callIconVisibility === WhatsAppCallingIconVisibility.DEFAULT;
        }
      }

      _updateSummaryBots();

      return success ?? false;
    } catch (error) {
      if (shouldShowToast) {
        showToast({
          type: 'danger',
          message: translate.enabledCalls.error,
        });
      }

      throw error;
    }
  };

  const onEnableIconVisibility = async (
    application: WhatsAppApplication,
    enable: boolean,
    shouldShowToast = true,
  ): Promise<boolean> => {
    try {
      const settings = {
        calling: {
          callIconVisibility: enable
            ? WhatsAppCallingIconVisibility.DEFAULT
            : WhatsAppCallingIconVisibility.DISABLE_ALL,
        },
      } as WhatsAppPhoneNumberSettings;

      const {
        response: {
          resource: { success, error },
        },
      }: { response: { resource: WhatsAppSetSettingsResponse } } = await ApplicationApi.sendApplicationRequest(
        'setApplicationPhoneNumberSettings',
        {
          tenantId: info.id,
          applicationIdentity: application.identity,
          settings,
        },
      );

      if (error) {
        throw error;
      }

      if (shouldShowToast) {
        showToast({
          type: 'success',
          message: translate.enabledCalls.iconVisibility,
        });
      }

      if (success) {
        application.isCallingIconVisible = enable;
      }

      return success ?? false;
    } catch (error) {
      application.isCallingIconVisible = !enable;

      if (shouldShowToast) {
        showToast({
          type: 'danger',
          message: translate.enabledCalls.error,
        });
      }
      throw error;
    }
  };

  const changeAllConfigCalling = async (enable: boolean) => {
    try {
      if (isEnablingCalling || isDisablingCalling) return;

      setIsEnablingCalling(enable);
      setIsDisablingCalling(!enable);
      const promises = configBots
        .filter((application) => application.selected)
        .map(async (application) => {
          const enableCalling = await onEnableCalling(application, enable, false);
          if (enableCalling) {
            application.isCallingEnabled = enable;
          }
        });

      await Promise.all(promises);

      showToast({
        type: 'success',
        message: translate.formatString(
          translate.enabledCalls.success,
          enable ? translate.enabledCalls.enabled : translate.enabledCalls.disabled,
        ),
      });
    } catch (error) {
      showToast({
        type: 'danger',
        message: translate.enabledCalls.error,
      });
    } finally {
      changeSelectAllBots(false);
      setIsEnablingCalling(false);
      setIsDisablingCalling(false);
    }
  };

  useEffect(() => {
    setHeaderContent({
      redirect: '/',
      text: translate.header.titleRedirect,
    });

    getBots()
      .then(setConfigBots)
      .finally(() => setIsLoading(false));

    return () => setHeaderContent({ redirect: null });
  }, []);

  useEffect(() => {
    if (configBots.length > 0) {
      filterBots(search);

      _updateSummaryBots();
    }
  }, [configBots, selectPerPage, page]);

  const changeSelectBot = ({ checked, name }: { checked: boolean; name: string }) => {
    setConfigBots((status) => {
      const newState = status.map((bot) => {
        if (bot.id === name) {
          return new WhatsAppApplication({ ...bot, selected: checked });
        }
        return bot;
      });
      return newState;
    });
  };

  const changeSelectAllBots = (selected: boolean) => {
    if (isEnablingCalling || isDisablingCalling) return;

    setSelectAllBots(selected);
    setConfigBots((status) => {
      const newState = status.map((bot) => {
        if (bot.hasPermission) {
          return new WhatsAppApplication({ ...bot, selected });
        }
        return bot;
      });
      return newState;
    });
  };

  const changePage = (event) => {
    const { detail: pageValue } = event;
    setPage(pageValue);
  };

  const _updateSummaryBots = useCallback(() => {
    const botsEnabled = configBots.filter((bot) => bot.isCallingEnabled).length;
    const botsDisabled = configBots.filter((bot) => !bot.isCallingEnabled).length;

    setBotsEnabled(botsEnabled);
    setBotsDisabled(botsDisabled);
  }, [configBots]);

  const pagesText = useMemo(() => {
    const startResult = totalRows <= 0 ? totalRows : selectPerPage * page - selectPerPage + 1;
    const endResult = selectPerPage * page > totalRows ? totalRows : selectPerPage * page;
    return translate.formatString(translate.page.result, startResult, endResult, totalRows);
  }, [page, selectPerPage, totalRows]);

  return (
    <Container>
      <span className="mb4">
        <BdsTypo variant="fs-24" tag="h1" bold="semi-bold" margin={false}>
          {translate.header.title}
        </BdsTypo>
      </span>
      <SubTitle className="mt5">
        <BdsTypo variant="fs-12" tag="h4" margin={false}>
          {translate.header.subTitle}
        </BdsTypo>
      </SubTitle>
      <BdsGrid gap="2">
        <Card>
          <div className="mb3">
            <BdsTypo bold="bold" variant="fs-14">
              {translate.summaryBots.enabled}
            </BdsTypo>
          </div>
          {isLoading ? (
            <BdsSkeleton width="50px" />
          ) : (
            <BdsTypo bold="bold" variant="fs-24" margin={false}>
              {botsEnabled ? botsEnabled.toString().padStart(2, '0') : '0'}
            </BdsTypo>
          )}
        </Card>
        <Card>
          <div className="mb3">
            <BdsTypo bold="bold" variant="fs-14">
              {translate.summaryBots.disabled}
            </BdsTypo>
          </div>
          {isLoading ? (
            <BdsSkeleton width="50px" />
          ) : (
            <BdsTypo bold="bold" variant="fs-24" margin={false}>
              {botsDisabled ? botsDisabled.toString().padStart(2, '0') : '0'}
            </BdsTypo>
          )}
        </Card>
      </BdsGrid>
      <div className="flex row mt3 w-100 items-center">
        <div className="w-30">
          <BlipSearch
            onChange={(searchTerm: string) => onFilter(searchTerm, search.filterByEnabled)}
            search={search.searchTerm}
            placeholder={translate.searchInput.text}
            debounce={500}
          />
        </div>
        <div className="w-70 flex justify-end row">
          <BdsSelect
            label={translate.filter.filterByLabel}
            className="mr2"
            value={search.filterByEnabled}
            onBdsChange={({ detail }) => onFilter(search.searchTerm, detail.value)}
            options={optionsFilterBots}
          />
          <BdsSelect
            label={translate.filter.paginationLabel}
            onBdsChange={({ detail }) => setSelectPerPage(detail.value)}
            options={optionsPerPage}
            value={selectPerPage}
          />
        </div>
      </div>
      {isLoading && <LoadingState />}
      {!isLoading && (
        <>
          {totalRows > 0 && (
            <div className="flex row items-center mt4 mb3 w-100 justify-between mnh4">
              <div className="flex row items-center">
                <BdsTypo variant="fs-16" margin={false}>
                  {translate.selectionBots.selectAll}
                </BdsTypo>
                <BdsCheckbox
                  checked={selectAllBots}
                  className="ml2"
                  onBdsChange={(event) => changeSelectAllBots(event.target.checked ?? false)}
                  disabled={isEnablingCalling || isDisablingCalling}
                  label={''}
                  name={''}
                  refer={''}
                />
              </div>
              {(showButtonEnabledAll || showButtonDisabledAll) && (
                <div className="flex row items-center">
                  <BdsTypo variant="fs-16" margin={false}>
                    {translate.formatString(translate.selectionBots.selected, amountSelectedBots)}
                  </BdsTypo>
                  {showButtonEnabledAll && (
                    <BdsButton
                      className="ml2"
                      variant="primary"
                      size="short"
                      onClick={() => changeAllConfigCalling(true)}
                      bdsLoading={isEnablingCalling}
                    >
                      {translate.selectionBots.enable}
                    </BdsButton>
                  )}
                  {showButtonDisabledAll && (
                    <BdsButton
                      className="ml2"
                      variant="ghost"
                      size="short"
                      onClick={() => changeAllConfigCalling(false)}
                      bdsLoading={isDisablingCalling}
                    >
                      {translate.selectionBots.disable}
                    </BdsButton>
                  )}
                </div>
              )}
            </div>
          )}
          <ListBotCalls
            bots={botsFiltered}
            translate={translate}
            onEnableCalling={onEnableCalling}
            onEnableIconVisibility={onEnableIconVisibility}
            onChangeSelectBot={(event) => changeSelectBot(event.target)}
          />
          {!botsFiltered.length && (
            <ListEmpty
              translate={translate.listEmpty}
              hasFilters={!!search.filterByEnabled}
              status={search.filterByEnabled === true}
              onChange={handleToggleFilter}
            />
          )}
          {totalRows > 0 && (
            <Footer>
              <BdsTypo variant="fs-14" margin={false}>
                {pagesText}
              </BdsTypo>
              <BdsPagination pages={pages} onBdsPaginationChange={changePage} options-position="top" />
            </Footer>
          )}
        </>
      )}
    </Container>
  );
};

export default Calls;
